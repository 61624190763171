import get from 'lodash-es/get';
import forEach from 'lodash-es/forEach';
import groupBy from 'lodash-es/groupBy';
import orderBy from 'lodash-es/orderBy';
import isEqual from 'lodash-es/isEqual';
import isEmpty from 'lodash-es/isEmpty';
import find from 'lodash-es/find';
import filter from 'lodash-es/filter';
import merge from 'lodash-es/merge';
import memoize from 'proxy-memoize';
import { getCountryName } from '../../utils.js';
import * as app from '../app';
import * as auth from '../auth';
import * as firestoreRedux from '@dreamworld/firestore-redux';
import { createSelector } from 'reselect';
import { serializeQueryParams, getUserIds, getIdWoPrefix } from '../../utils';

/**
 * @returns {Object} devices. User's accessed devices. e.g. { DESKTOP: true, MOBILE: true }
 */
const userDevices = memoize(
  (devices) => {
    let _devices = {};
    forEach(devices, (device) => {
      _devices[get(device, 'type')] = true;
    });
    return _devices;
  },
  { size: 1000 }
);

const SUPPORTED_BROWSERS = ['CHROME', 'SAFARI', 'FIREFOX', 'EDGE', 'OTHER'];
/**
 * @returns {Array} browsers. User's accessed browsers. e.g. [{ name: 'CHROME', lastAccessed: [{ appVersion, pwaVersion, os, lastAccessedTime }, ...]}, ...]
 */
const userBrowsers = memoize(
  (devices) => {
    const groupedList = groupBy(devices, (device) => get(device, 'browser'));
    let _list = [];
    forEach(SUPPORTED_BROWSERS, (name) => {
      if (isEmpty(groupedList[name])) {
        return;
      }

      const browserWiseLastSeen = orderBy(groupedList[name], ['time'], ['desc']);
      let lastAccessed = [];
      forEach(browserWiseLastSeen, (lastSeen) => {
        lastAccessed.push({ version: lastSeen.browserVersion, os: lastSeen.os, osVersion: lastSeen.osVersion, lastAccessedTime: lastSeen.time, pwaVersion: lastSeen.pwaVersion, appVersion: lastSeen.appVersion });
      });

      _list.push({ name, lastAccessed });
    });
    return _list;
  },
  { size: 1000 }
);

/**
 * @returns {Array} list of users. e.g. [{id, firstName, lastName, email, profileImage, online, pending, signupTime, lastSeenTime, devices, browsers, ownedAccountsCount, joinedAccountsCount }, ...]
 */
export const list = memoize((state) => {
  let _list;
  const _result = get(state, `user-list.result.users`);
  // When no records found.
  if (_result && !_result.length) {
    return _result;
  }

  forEach(_result, (user) => {
    const firestoreUser = firestoreRedux.selectors.doc(state, 'users', user.id);
    if (firestoreUser && firestoreUser.deleted) {
      return;
    }

    if (!_list) {
      _list = [];
    }

    const { id, name, email, profileImage, pending, signupTime, ownedAccounts, joinedAccounts, additionalEmails } = user;
    const arr = name && name.includes(' ') ? name.split(' ') : name ? [name] : [];
    const firstName = arr[0] || '';
    arr.shift();
    const lastName = arr.join(' ') || '';
    const userOnlineTime = 5 * 60 * 1000; // 5 minutes.
    const lastSeen = user && user.lastSeen || {};
    const lastSeenTime = lastSeen ? lastSeen.time || 0 : 0;
    const online = lastSeenTime >= Date.now() - userOnlineTime; // When user is online in last 5 minutes.
    const devices = lastSeen && lastSeen.devices ? userDevices(lastSeen.devices) : [];
    const browsers = lastSeen && lastSeen.devices ? userBrowsers(lastSeen.devices) : [];
    const countryCode = lastSeen && lastSeen.country && lastSeen.country.code || '';
    const country = getCountryName(countryCode);
    const countryFlag = countryCode ?  `https://flagcdn.com/48x36/${countryCode.toLowerCase()}.png`: '';

    const utmDetails = user && user.utmDetails || {};

    const preference = user.preference || {};
    const lang = preference.lang;
    const presentLangSelection = preference.presentLangSelection;

    _list.push({
      id,
      firstName,
      lastName,
      email,
      profileImage,
      online,
      pending,
      signupTime,
      lastSeenTime,
      devices,
      browsers,
      additionalEmails: additionalEmails || [],
      ownedAccountsCount: ownedAccounts && ownedAccounts.length,
      joinedAccountsCount: joinedAccounts && joinedAccounts.length,
      country,
      countryCode,
      countryFlag,
      lang,
      presentLangSelection,
      utmDetails
    });
  });

  return _list;
});

/**
 * @param {Object} state Redux state.
 * @returns {Number} Last request page number.
 */
export const lastRequestPage = (state) => get(state, `user-list.lastRequest.page`);

/**
 *
 * @param { Object } state Redux state
 * @returns { String } status of the last request.
 */
export const lastRequestStatus = (state) => get(state, `user-list.lastRequest.status`);

/**
 * @param {Object} state Redux state
 * @returns {Boolean} `true` when request is in-progress.
 */
export const requestInProgress = (state) => {
  const _lastRequestStatus = lastRequestStatus(state);
  return _lastRequestStatus === 'IN_PROGRESS';
};

/**
 * @override
 * @returns {Number} Records per page to be loaded.
 */
export const recordsPerPage = app.selectors.recordsPerPage;

/**
 * @returns { Boolean } `true` when all the records are loaded.
 */
export const eol = memoize((state) => {
  const _list = list(state) || [];
  const _rpp = recordsPerPage(state);
  const _lastRequestPage = lastRequestPage(state);
  const _lastRequestStatus = lastRequestStatus(state);
  return _lastRequestStatus === 'SUCCESS' && _rpp * _lastRequestPage > _list.length;
});

/**
 * @returns {Object} Default filters.
 */
export const defaultFilters = () => {
  return {
    name: '',
    email: '',
    userId: '',
    signupFrom: null,
    signupTo: null,
    lastSeenFrom: null,
    lastSeenTo: null,
    live: null,
    userType: null,
    signupType: null,
    authType: null,
    device: null,
    browser: null,
    lang: null,
    countryCode: null,
    presentLangSelection: null,
    initUtmCampaign: null,
    initUtmSource: null,
    initUtmMedium: null,
    firstSession: {
      totalDuration: null,
      activityDuration: null,
      os: null,
      device: null,
      browser: null,
    },
    jobRole: null,
    taskBoardAware: null,
    taskBoardAwareUnknown: null
  };
};

/**
 * @returns { Object } Current applied filters. When no filters are applied, default filters.
 */
export const filters = memoize((state) => {
  const params = get(state, `router.page.params`);
  const urlFilters = {};
  forEach(params, (value, key) => {
    if(key in defaultFilters() && value){
      if(key === 'initUtmCampaign' || key === 'initUtmSource' || key === 'initUtmMedium' || key === 'lang' || key === 'userType' || key === 'signupType' || key === 'authType' || key === 'device' || key === 'browser' || key === 'countryCode'){
        urlFilters[key] = value.split(',');
      } else{
        urlFilters[key] = value;
      }
    }
  });
  return {...defaultFilters(), ...urlFilters, ...get(state, `user-list.filters`, {})};
});

/**
 * @param {Object} state Redux state.
 * @returns {String} Sort By field name.
 */
export const sortBy = (state) => {
  return get(state, `user-list.sortBy`) || get(state, `router.page.params.sortBy`) || 'SIGNUP'
};

/**
 * @param {Object} state Redux state.
 * @returns {String} Sort order of the list.
 */
export const sortOrder = (state) => {
  return get(state, `user-list.sortOrder`) || get(state, `router.page.params.sortOrder`) || 'DESC';
};

/**
 * @param {Object} param0
 *  @property {Object} state Redux state.
 *  @property {String} userId User Id.
 * @returns {String} User's stripe customer url.
 */
export const stripeCustomerUrl = ({ state, userId }) => {
  const { stripeBaseUrl } = app.selectors.config(state);
  const stripeCustomerId = get(state, `firebase.subscriptions.stripe-customer.${userId}`);
  return stripeCustomerId ? `${stripeBaseUrl}/customers/${stripeCustomerId}` : '';
};

const _getAccountItemDetail = (state, id) => {
  const { name } = firestoreRedux.selectors.doc(state, 'accounts', id) || {};
  const subscription = get(state, `firebase.subscriptions.${id}.subscription`) || {};
  const pendingUsers = filter(get(state, `firestore.docs.account-team-members`), (member) => member.accountId === id && !member.joinedAt).length;
  return {
    id,
    name,
    plan: subscription.plan,
    expireAt: subscription.expirationDate,
    usedSubscriptions: subscription.usedAnnualSubscriptions,
    totalSubscriptions: subscription.plan === 'SOLO' ? 1 : subscription.totalAnnualSubscriptions,
    pendingUsers,
  };
};

/**
 * @returns {Object} User detail. e.g. {id, firstName, lastName, email, auths, signupTime, lastLoginTime, lastSeenTime, lastLocation, ownedAccounts, joinedAccounts }
 */ 
export const userDetail = memoize(({ state, id }) => {
  const userListItem = find(get(state, `user-list.result.users`), { id });
  const userAttrs = firestoreRedux.selectors.doc(state, 'users', id);

  const userIdWoPrefix = getIdWoPrefix({ id: id, prefix: 'usr_' });
  const userPreferences = firestoreRedux.selectors.doc(state, 'user-preferences', `up_${userIdWoPrefix}`) || {};
  const listItemPreferences = userListItem && userListItem.preference || {};
  const preferences = merge({}, listItemPreferences, userPreferences);
  const userPreferencesLang = preferences.lang || null;
  const presentLangSelection = preferences.presentLangSelection || false;
  const supportedLanguages = auth.selectors.supportedLanguages(state);
  let lang;
  forEach(supportedLanguages, (item) => {
    if(item && item.code === userPreferencesLang) {
      lang = item.name;
    }
  });
  lang = lang || (userListItem && userListItem.preference && userListItem.preference.lang) || '';

  const lastSeen = find(get(state, `firestore.docs.lastseen-details`), { userId: id }) || {};
  const lastSeenTime = lastSeen.time;
  const lastLocation = lastSeen.ip;
  const countryCode = lastSeen.country && lastSeen.country.code || (userListItem && userListItem.country && userListItem.country.code) || '';
  const country = getCountryName(countryCode);
  const countryFlag = countryCode ?  `https://flagcdn.com/48x36/${countryCode.toLowerCase()}.png`: '';

  // Owned accounts.
  const _ownedAccounts = filter(get(state, `firestore.docs.accounts`), { ownerId: id });
  let ownedAccounts = [];
  forEach(_ownedAccounts, (account) => {
    const _account = _getAccountItemDetail(state, account.id);
    ownedAccounts.push(_account);
  });

  // Joined accounts.
  const _teamMembers = filter(get(state, `firestore.docs.account-team-members`), { userId: id });
  let joinedAccounts = [];
  forEach(_teamMembers, (member) => {
    const _account = _getAccountItemDetail(state, member.accountId);
    joinedAccounts.push(_account);
  });

  const _devices = get(userListItem, 'lastSeen.devices', []);
  const devices = userDevices(_devices);
  const browsers = userBrowsers(_devices);

  const utmDetails = userListItem && userListItem.utmDetails || {};

  const userProperties = firestoreRedux.selectors.doc(state, 'user-properties', `ups_${userIdWoPrefix}`) || {};
  const listItemProperties = userListItem && userListItem.properties || {};
  const properties = merge({}, listItemProperties, userProperties);
  const taskBoardAware = properties && properties.taskBoardAware;
  const jobRole = properties && properties.jobRole || null;
  const firstSession = properties && properties.firstSession || {};

  return {
    ...userListItem,
    ...userAttrs,
    lastSeenTime,
    lastLocation,
    ownedAccounts,
    joinedAccounts,
    devices,
    browsers,
    lang,
    country,
    countryCode,
    countryFlag,
    presentLangSelection,
    utmDetails,
    taskBoardAware,
    jobRole,
    firstSession
  };
});

/**
 * @returns {String} excel sheet link with filter criteria.
 */
export const exportAsExcelLink = memoize((state) => {
  const config = app.selectors.config(state);
  const {apiBaseUrl} = config;
  const _filters = filters(state);
  const _pageSize = recordsPerPage(state);
  const _pageNo = lastRequestPage(state) || 1;
  const _sortBy = sortBy(state);
  const _sortOrder = sortOrder(state);
  let params = { pageNo: _pageNo, pageSize: _pageSize, sortBy: _sortBy, sortOrder: _sortOrder };
  forEach(_filters, (value, key) => {
    if (value) {
      if(key === 'userIds') {
        value = getUserIds(value);
      }

      if(key === 'firstSession') {
        forEach(value, (_value, _key) => {
          if(_value) {
            if (Array.isArray(_value)) {
              params[`${key}_${_key}`] = _value.join(',');
            } else {
              params[`${key}_${_key}`] = _value;
            }
          }
        });
      } else {
        if (Array.isArray(value)) {
          params[key] = value.join(',');
        } else {
          params[key] = value;
        }
      }
    }
  });

  const queryString = serializeQueryParams(params);
  const link = `${apiBaseUrl}/user/users/export?${queryString}`;
  return link;
});

/**
 * @returns {Boolean} country list status.
 * Possible values: IN_PROGRESS, SUCCESS, FAILED.
 */
export const countryListStatus = (state) => get(state, `user-list.country.status`, 'IN_PROGRESS');

/**
 * @param {Object} state redux state
 * @returns {Array} country list e.g. [{name: 'India', code: 'IN', count:10}, {name: 'Australia', code: 'AU', count:20}]
 */
export const countryList = createSelector(
  (state) => get(state, `user-list.country.list`, {}),
  (state) => filters(state),
  (_list, _filters) => {
    const _countryLists = [];
    const countryCode = _filters && _filters.countryCode || [];
    forEach(_list, (count, code) => {
      count = count || 0;
      if(!count && !countryCode.includes(code)) {
        return true;
      }
      _countryLists.push({ name: getCountryName(code), code, count});
    });

    if(isEmpty(_countryLists) && _filters && !isEmpty(countryCode)) {
      forEach(countryCode, (code) => {
        _countryLists.push({ name: getCountryName(code), code, count: 0});
      });
    }

    return orderBy(_countryLists, ['name'], ['count']);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param {Object} state redux state
 * @returns {Boolean} country filter is applied or not.
 */
export const hasCountryFilter = createSelector(
  (state) => filters(state),
  (_filters) => {
    return _filters && _filters.countryCode && !isEmpty(_filters.countryCode) || false;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @returns {String} bulk-edit status.
 */
export const bulkEditStatus = (state) => get(state, `user-list.bulkEdit.status`);

/**
 * @returns {Number} total user counts.
 */
export const totalCount = (state) => {
  return get(state, `user-list.result.totalCount`, 0) || 0;
} 

/**
 * @returns {Number} match filtred user counts.
 */
export const matchCount = (state) => {
  return get(state, `user-list.result.matchCount`, 0) || 0;
}

/**
 * @returns {Boolean} utm list status.
 * Possible values: IN_PROGRESS, SUCCESS, FAILED.
 */
export const utmListStatus = (state) => get(state, `user-list.utmList.status`, 'IN_PROGRESS');

/**
 * @returns {Array} utm campaign list e.g. [{name: 'trello', id: 'trello', count:10}, {name: 'asana', id: 'asana', count:20}]
 */
export const utmCampaignList = createSelector(
  (state) => get(state, 'user-list.utmList.list.initUtmCampaign', {}),
  (state) => filters(state),
  (_list, _filters) => {
    const _utmCampaignList = [];
    const initUtmCampaign = _filters && _filters.initUtmCampaign || [];
    forEach(_list, (count, name) => {
      count = count || 0; 
      if(!count && !initUtmCampaign.includes(name)) {
        return true;
      }
      _utmCampaignList.push({ id: name, name, count});
    });

    if(isEmpty(_utmCampaignList) && _filters && !isEmpty(initUtmCampaign)) {
      forEach(initUtmCampaign, (name) => {
        _utmCampaignList.push({ id: name, name, count: 0});
      });
    }

    return orderBy(_utmCampaignList, ['name'], ['count']);
  }
);

/**
 * @returns {Array} utm source list e.g. [{name: 'test1', id: 'test1', count:10}, {name: 'test2', id: 'test2', count:20}]
 */
export const utmSourceList = createSelector(
  (state) => get(state, 'user-list.utmList.list.initUtmSource', {}),
  (state) => filters(state),
  (_list, _filters) => {
    const _utmSourceList = [];
    const initUtmSource = _filters && _filters.initUtmSource || [];
    forEach(_list, (count, name) => {
      count = count || 0; 
      if(!count && !initUtmSource.includes(name)) {
        return true;
      }
      _utmSourceList.push({ id: name, name, count});
    });

    if(isEmpty(_utmSourceList) && _filters && !isEmpty(initUtmSource)) {
      forEach(initUtmSource, (name) => {
        _utmSourceList.push({ id: name, name, count: 0});
      });
    }

    return orderBy(_utmSourceList, ['name'], ['count']);
  }
);


/**
 * @returns {Array} utm medium list e.g. [{name: 'kerika1', id: 'kerika1', count:10}, {name: 'kerika2', id: 'kerika2', count:20}]
 */
export const utmMediumList = createSelector(
  (state) => get(state, 'user-list.utmList.list.initUtmMedium', {}),
  (state) => filters(state),
  (_list, _filters) => {
    const _utmMediumList = [];
    const initUtmMedium = _filters && _filters.initUtmMedium || [];
    forEach(_list, (count, name) => {
      count = count || 0; 
      if(!count && !initUtmMedium.includes(name)) {
        return true;
      }
      _utmMediumList.push({ id: name, name, count});
    });

    if(isEmpty(_utmMediumList) && _filters && !isEmpty(initUtmMedium)) {
      forEach(initUtmMedium, (name) => {
        _utmMediumList.push({ id: name, name, count: 0});
      });
    }

    return orderBy(_utmMediumList, ['name'], ['count']);
  }
);


/**
 * @returns {Boolean} total duration list status.
 * Possible values: IN_PROGRESS, SUCCESS, FAILED.
 */
export const totalDurationListStatus = (state) => get(state, `user-list.firstSession.totalDuration.status`, 'IN_PROGRESS');

/**
 * @param {Object} state redux state
 * @returns {Array} total duration list
 */
export const totalDurationList = createSelector(
  (state) => get(state, `user-list.firstSession.totalDuration.list`, {}),
  (state) => filters(state),
  (_list, _filters) => {
    const _totalDurationList = [];
    const totalDuration = _filters &&  _filters.firstSession && _filters.firstSession.totalDuration || [];
    forEach(_list, (count, name) => {
      count = count || 0;
      if(!count && !totalDuration.includes(name)) {
        return true;
      }
      _totalDurationList.push({id: name, name, count});
    });

    if(isEmpty(_totalDurationList) && _filters && !isEmpty(totalDuration)) {
      forEach(totalDuration, (name) => {
        _totalDurationList.push({id: name, name, count: 0});
      });
    }

    return orderBy(_totalDurationList, ['name'], ['count']);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param {Object} state redux state
 * @returns {Boolean} total duration filter is applied or not.
 */
export const hasTotalDurationFilter = createSelector(
  (state) => filters(state),
  (_filters) => {
    return _filters && _filters.firstSession.totalDuration && !isEmpty(_filters.firstSession.totalDuration) || false;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);


/**
 * @returns {Boolean} total duration list status.
 * Possible values: IN_PROGRESS, SUCCESS, FAILED.
 */
export const activityDurationListStatus = (state) => get(state, `user-list.firstSession.activityDuration.status`, 'IN_PROGRESS');

/**
 * @param {Object} state redux state
 * @returns {Array} activity duration list
 */
export const activityDurationList = createSelector(
  (state) => get(state, `user-list.firstSession.activityDuration.list`, {}),
  (state) => filters(state),
  (_list, _filters) => {
    const _activityDurationList = [];
    const activityDuration = _filters && _filters.firstSession && _filters.firstSession.activityDuration || [];
    forEach(_list, (count, name) => {
      count = count || 0;
      if(!count && !activityDuration.includes(name)) {
        return true;
      }
      _activityDurationList.push({id: name, name, count});
    });

    if(isEmpty(_activityDurationList) && _filters && !isEmpty(activityDuration)) {
      forEach(activityDuration, (name) => {
        _activityDurationList.push({id: name, name, count: 0});
      });
    }

    return orderBy(_activityDurationList, ['name'], ['count']);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @returns {Boolean} os list status.
 * Possible values: IN_PROGRESS, SUCCESS, FAILED.
 */
export const osListStatus = (state) => get(state, `user-list.firstSession.os.status`, 'IN_PROGRESS');

/**
 * @param {Object} state redux state
 * @returns {Array} os list
 */
export const osList = createSelector(
  (state) => get(state, `user-list.firstSession.os.list`, {}),
  (state) => filters(state),
  (_list, _filters) => {
    const _osList = [];
    const os = _filters && _filters.firstSession && _filters.firstSession?.os || [];
    forEach(_list, (count, name) => {
      count = count || 0;
      if(!count && !os.includes(name)) {
        return true;
      }
      _osList.push({id: name, name, count});
    });

    if(isEmpty(_osList) && _filters && !isEmpty(os)) {
      forEach(os, (name) => {
        _osList.push({id: name, name, count: 0});
      });
    }

    return orderBy(_osList, ['name'], ['count']);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param {Object} state redux state
 * @returns {Boolean} total duration filter is applied or not.
 */
export const hasOsListFilter = createSelector(
  (state) => filters(state),
  (_filters) => {
    return _filters && _filters.firstSession.os && !isEmpty(_filters.firstSession.os) || false;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @returns {Boolean} job-role list status.
 * Possible values: IN_PROGRESS, SUCCESS, FAILED.
 */
export const jobRoleListStatus = (state) => get(state, `user-list.jobRole.status`, 'IN_PROGRESS');

export const getJobRoleName = createSelector(
  (list, role) => list,
  (list, role) => role,
  (list, role) => {
    let name = role;
    forEach(list, (item) => {
      if(item && item.id === role) {
        name = item.name;
        return false;
      }
    });
    return name;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 50,
    },
  }
);

export const jobRoleItems = createSelector(
  (state) => firestoreRedux.selectors.doc(state, 'user-property-definitions', `job-role`),
  (doc) => {
    const options = get(doc, 'options', []) || [];
    const newOptions = [];
    forEach(options, (option) => {
      const id = get(option, 'value');
      if(id) {
        newOptions.push({id, name: get(option, 'label.en')});
      }
    })
    return newOptions;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param {Object} state redux state
 * @returns {Array} os list
 */
export const jobRoleList = createSelector(
  (state) => jobRoleItems(state),
  (state) => get(state, `user-list.jobRole.list`, {}),
  (state) => filters(state),
  (_fsList, _list, _filters) => {
    const _jobRoleList = [];
    const jobRole = _filters && _filters.jobRole || [];
    forEach(_list, (count, name) => {
      count = count || 0;
      if(!count && !jobRole.includes(name)) {
        return true;
      }
      _jobRoleList.push({id: name, name: getJobRoleName(_fsList, name), count});
    });

    if(isEmpty(_jobRoleList) && _filters && !isEmpty(jobRole)) {
      forEach(jobRole, (name) => {
        _jobRoleList.push({id: name, name: getJobRoleName(_fsList, name), count: 0});
      });
    }

    return orderBy(_jobRoleList, ['name'], ['count']);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param {Object} state redux state
 * @returns {Boolean} total duration filter is applied or not.
 */
export const hasJobRoleListFilter = createSelector(
  (state) => filters(state),
  (_filters) => {
    return _filters && _filters.jobRole && !isEmpty(_filters.jobRole) || false;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @param {Object} state redux state
 * @returns {Array} os list
 */
export const taskboardAwareList = createSelector(
  (state) => get(state, `user-list.taskboardAware.list`, {}),
  (state) => filters(state),
  (_list, _filters) => {
    const _taskboardAwareList = [];
    const taskboardAware = _filters && _filters.taskBoardAware || [];
    forEach(_list, (count, name) => {
      count = count || 0;
      if(!count && !taskboardAware.includes(name)) {
        return true;
      }
      _taskboardAwareList.push({id: name, name: name, count});
    });

    if(isEmpty(_taskboardAwareList) && _filters && !isEmpty(taskboardAware)) {
      forEach(taskboardAware, (name) => {
        _taskboardAwareList.push({id: name, name: name, count: 0});
      });
    }

    return orderBy(_taskboardAwareList, ['name'], ['count']);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      maxSize: 10,
    },
  }
);

/**
 * @returns {Boolean} job-role list status.
 * Possible values: IN_PROGRESS, SUCCESS, FAILED.
 */
export const taskboardAwareListStatus = (state) => get(state, `user-list.taskboardAware.status`, 'IN_PROGRESS');