import fetch from '@dreamworld/fetch';
import { show as showSnackBar } from './components/common/kerika-snackbar.js';
import i18next from './localize/i18next.js';

/**
 * It retry fetch request internally if if failed due to network error
 * @param {String} url API endpoint url
 * @param {Object} options Request options. It contains `retryable`
 * @param {Number} maxAttempts Maximum retry attempt. Default is 5
 * @param {Number} delay delay between retry. Default is 200.
 * @returns {Promise}
 */
export default async (url, options = {}, maxAttempts = 5, delay = 200) => {
  if (url && url.startsWith('/')) {
    url = window.K.config.apiBaseUrl + url;
  }

  //Options for CORS request
  if (!options.mode) {
    options.mode = 'cors';
  }

  if (!options.credentials) {
    options.credentials = 'include';
  }

  if (!options.headers) {
    options.headers = {
      'Content-Type': 'application/json',
    };
  }

  // Stringify request body.
  if (options.body) {
    options.body = JSON.stringify(options.body);
  }

  const aExcludeErrors = Array.isArray(options.excludeErrors) ? [...options.excludeErrors] : [];
  delete options.excludeErrors;

  const response = await fetch(url, options, maxAttempts, delay);

  // Dispatch 'authentication-denied' event on window when API is failed due to user is not logged in
  if (response.status && response.status === 401) {
    window.dispatchEvent(new CustomEvent('authentication-denied', {}));
    return new Promise(() => {});
  }

  let responseText;
  try {
    responseText = await response.text();
    responseText = responseText.trim();
  } catch (err) {
    //Logged as an error only if it did not fail due to a network error or abort request.
    if (err && err.code && err.code != err.ABORT_ERR) {
      console.error('Failed to retrieve responseText', err);
    }
  }

  let responseJSON;
  try {
    responseJSON = JSON.parse(responseText);
  } catch (e) {
    //ignore
  }

  if (response.ok) {
    return getResponseObject(responseJSON, responseText, response.status);
  }

  let body = responseText || JSON.stringify(responseJSON || {});

  let toastActionButton;
  if (options.method && options.method.toLowerCase() !== 'get') {
    const reload = () => window.location.reload();
    toastActionButton = { caption: i18next.t('buttons.reload'), callback: reload };
  }

  if(responseJSON && responseJSON.code === 'ACCESS_COOKIE_NOT_AVAILABLE') {
    // Dispatch 'access-cookie-not-available' event on window when API is failed due to access cookie is not available
    window.dispatchEvent(new CustomEvent('access-cookie-not-available', {}));
  }

  if (!aExcludeErrors.includes(response.status) && response.status !== 503) {
    // If it's not expected error by the requester, need to log this as an Sentry error.
    if (response && response.status === 504) {
      showSnackBar({ message: i18next.t('toast.504') });
    } else {
      const message = response && response.statusText || responseJSON && responseJSON.message || `API failed. status-code: ${response.status}`;
      console.error(`API failed. url: ${url}, statuscode: ${response.status}, body:${body}`);
      showSnackBar({ message, type: 'ERROR', actionButton: toastActionButton });
    }
  }

  return getResponseObject(responseJSON, responseText, response.status);
};

let getResponseObject = (json, text, status) => {
  let error = status && status >= 400;
  if (!error) {
    if (json) {
      return json;
    }

    if (!text) {
      return;
    }
  }

  if (json) {
    throw { ...json, status };
  }

  throw {
    status: status,
    code: 'JSON_PARSE_FAILED',
    error: 'Failed to parse resposne as Json.',
    text: text,
  };
};

/**
 * @param {Object} error Error
 * @returns `true` when error or error code is not found.
 */
export const isNetworkError = (error) => {
  return !error ||  (error.code && error.ABORT_ERR && error.code == error.ABORT_ERR) || (!error.code && !error.status);
}